



















































import { Component, Vue } from 'vue-property-decorator'
import AreaSelect from '@/components/area-select.vue'
import uwUser from './components/user.vue'
import { apiAgentAdd, apiAgentEdit, apiAgentDetail } from '@/api/user'
	@Component({
	  components: {
	    AreaSelect,
	    uwUser
	  }
	})
export default class AddAgent extends Vue {
		// 定义表单数据
		form = {
		  user_id: '',
		  name: '',
		  mobile: '',
		  payment_amount: '',
		  province: '',
		  city: '',
		  district: '',
		  status: 1
		};

		isEdit = false
		nickname = ''
		// 定义校验规则
		rules = {
		  user_id: [
		    // { required: true, message: '请选择关联用户', trigger: 'blur' }
		  ],
		  name: [
		    { required: true, message: '请输入代理用户名', trigger: 'blur' }
		  ],
		  mobile: [
		    { required: true, message: '请输入手机号', trigger: 'blur' }
		  ],
		  payment_amount: [
		    { required: true, message: '请输入授信金额', trigger: 'blur' }
		  ],
		  province: [
		    { required: true, message: '请选择代理区域', trigger: 'blur' }
		  ],
		  city: [
		    { required: true, message: '请选择代理区域', trigger: 'blur' }
		  ],
		  district: [
		    { required: true, message: '请选择代理区域', trigger: 'blur' }
		  ],
		  status: [
		    { required: true, message: '请选择代理状态', trigger: 'blur' }
		  ]
		};

		getUserChange (row:any) {
      console.log('获取用户信息:', row)
		  this.nickname = row.nickname || ''
		  this.form.user_id = row.id
		}

		// 提交表单
		onSubmit () {
		  (this.$refs.form as any).validate((valid : boolean) => {
		    if (valid) {
		      if (this.isEdit) {
		        apiAgentEdit(this.form).then(() => {
		          this.$router.go(-1)
		        })
		      } else {
		        apiAgentAdd(this.form).then(() => {
		          this.$router.go(-1)
		        })
		      }
		    }
		  })
		}

		showLogs () {
		  const userRef = this.$refs.userRef as any
		  if (userRef) {
		    userRef.dialogVisible = true // 控制弹窗显示
		    userRef.getUserLists()
		  }
		}

		// 取消表单
		onCancel () {
		  console.log('取消操作')
		}

		created () {
		  if (this.$route.query.id) {
		    this.isEdit = true
		    apiAgentDetail({
		      id: this.$route.query.id
		    }).then((res) => {
		      this.form = res
		      this.nickname = res.nickname
		    })
		  }
		}
}




































































import Vue from 'vue'
import Component from 'vue-class-component'
import SettlementRecords from './components/jilu.vue' // 引入结算记录组件
import { apiAgentLists } from '@/api/user'
	@Component({
	  components: {
	    SettlementRecords
	  }
	})
export default class AgentList extends Vue {
		createTime : string | string[] = '';
		form = {
		  keywords: '',
		  create_start_time: 0,
		  create_end_time: 0,
		  status: null,
		  page_no: 1,
		  page_size: 10
		}

		total = 0
		tableData : Array<any> = [];

		// 方法
		search () {
		  this.getAgentLists()
		}

		changeTime () {
		  if (this.createTime) {
		    this.form.create_start_time = new Date(this.createTime[0]).getTime() / 1000
		    this.form.create_end_time = new Date(this.createTime[1]).getTime() / 1000
		  } else {
		    this.form.create_start_time = 0
		    this.form.create_end_time = 0
		  }
		  this.getAgentLists()
		}

		reset () {
		  this.form = {
		    keywords: '',
		    create_start_time: 0,
		    create_end_time: 0,
		    status: null,
		    page_no: 1,
		    page_size: 10
		  }
		  this.createTime = ''
		  this.getAgentLists()
		}

		addAgent () {
		  this.$router.push({ name: 'agentr_add' })
		}

		refresh () {
		  this.$router.go(0)
		}

		viewDetails (row : any) {
		  this.$router.push({ name: 'agentr_detail', query: { id: row.id } })
		}

		edit (row : any) {
		  this.$router.push({ name: 'agentr_add', query: { id: row.id } })
		}

		handlePageChange (page : number) {
		  this.form.page_no = page
		  this.getAgentLists()
		}

		getAgentLists () {
		  apiAgentLists(this.form).then((res) => {
		    this.tableData = res.lists
		    this.total = res.count
		  })
		}

		// 显示结算记录弹窗
		showLogs ({ id }:{id:any}) {
		  const settlementRecords = this.$refs.settlementRecords as any
		  if (settlementRecords) {
		    settlementRecords.dialogVisible = true // 控制弹窗显示
		    settlementRecords.getAgentSettledLog(id)
		  }
		}

		created () {
		  this.getAgentLists()
		}
}































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { apiAccountLog, apiChangeTypeList, apiBnwChangeType } from '@/api/finance/finance'
import { PageMode } from '@/utils/type'
import { RequestPaging } from '@/utils/util'
import LsPagination from '@/components/ls-pagination.vue'
import ExportData from '@/components/export-data/index.vue'
@Component({
  components: {
    LsPagination,
    ExportData
  }
})
export default class AccountLog extends Vue {
    /** S Data **/
    // 日期选择器
    pickerOptions = {
      shortcuts: [
        {
          text: '最近一周',
          onClick (picker: any) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        },
        {
          text: '最近一个月',
          onClick (picker: any) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        },
        {
          text: '最近三个月',
          onClick (picker: any) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }
      ]
    }

    tableData = []
    // 顶部查询表单
    pager: RequestPaging = new RequestPaging()
    isNameSN = '' // 选择用户编号0 选择用户昵称1 手机号码2
    // 顶部查询表单
    form = {
      nickname: '', // 用户昵称
      sn: '', // 用户编号
      changeType: '', // 变动类型
      start_time: '',
      end_time: '',
      mobile: '' // 手机号
    }

    // 变动类型数据
    changeTypeList = []

    apiAccountLog = apiAccountLog
    /** E Data **/

    // 监听用户信息查询框条件
    @Watch('isNameSN', {
      immediate: true
    })
    getChange (val: any) {
      // 初始值
      this.form.sn = ''
      this.form.nickname = ''
      this.form.mobile = ''
    }

    /** S Methods **/
    splitTime () {
      if (this.tableData != null) {
        this.form.start_time = this.tableData[0]
        this.form.end_time = this.tableData[1]
      }
    }

    // 重置
    onReset () {
      this.form = {
        nickname: '', // 用户昵称
        sn: '', // 用户编号
        changeType: '', // 变动类型
        start_time: '',
        end_time: '',
        mobile: '' // 手机号
      }
      this.tableData = []

      this.getList()
    }

    // 资金记录
    getList (page?: number): void {
      page && (this.pager.page = page)
      this.pager
        .request({
          callback: apiAccountLog,
          params: {
            ...this.form,
            type: 'bnw' // 类型 bnw-不可提现余额类型 integral-积分类型
          }
        })
        .then((res: any) => {})
    }

    // 变动类型数据
    getSearchList () {
      apiBnwChangeType().then((res: any) => {
        this.changeTypeList = res
      })
    }
    /** E Methods **/

    /** S Life Cycle **/
    created () {
      this.getList()
      this.getSearchList()
    }
    /** E Life Cycle **/
}
